<template>
    <v-row class="px-3 flex-column flex-sm-row justify-sm-space-between align-items-start mt-1 mb-5">
        <router-link to="/module-inscription" class="text-decoration-none">
            <v-btn color="primary" class="font-weight-bold d-none d-sm-block mb-3">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="primary" class="font-weight-bold d-sm-none w-100 mb-3">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
        </router-link>
        <div class="d-flex flex-column align-items-end">

            <v-btn :disabled="!hasPreparations" @click="handleSubmitLead('/confirmation-inscription')" color="secondary" class="font-weight-bold d-none d-sm-block mb-2">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
            <v-btn :disabled="!hasPreparations" @click="handleSubmitLead('/confirmation-inscription')" color="secondary" class="font-weight-bold d-sm-none w-100 mb-1">
                C<span class="text-lowercase">onfirmer cette inscription</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>

            <router-link :class="{'d-none': !hasPreparations}" @click="handleSubmitLead('/confirmation-recall')" to="" class="text-primary">
                Vous hésitez ? Faites vous rappeler
            </router-link>
        </div>

        <div class="mt-3">
            <v-alert
                v-if="errorMessage"
                type="error"
                class="mb-3"
                >
                {{ errorMessage }}
            </v-alert>
        </div>

        <v-overlay v-model="submitLoading" class="d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
            >
            </v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from "moment";

import { LeadStatus } from "@/constants/lead-status";

export default {
    data() {
        return {
            errorMessage: '',
            submitLoading: false,
        };
    },

    computed: {
        ...mapGetters('payload', ['getParent', 'getStudents']),

        hasPreparations() {
            return Object.values(this.getStudents).some(student =>
                student.preparations && Object.keys(student.preparations).length > 0
            );
        },

        sectors() {
            var sectors = "";
            Object.values(this.getStudents).forEach(student => {
                if (student.preparations) {
                    Object.values(student.preparations).forEach(preparation => {
                        const productName = preparation.product_name;
                        if (sectors.length > 0) {
                            if (!sectors.includes(productName)) {
                                sectors += ", " + productName;
                            }
                        } else {
                            sectors = productName;
                        }
                    });
                }
            });
            return sectors;
        }
    },

    methods: {

        createDatalayer(event) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': event,
                'email': event,
                'user': 'new_user',
                'new_customer': true,
                'filière': this.sectors,
                'amount': this.calculateTotalPrice()
            })
        },

        calculateTotalPrice() {
            let price = 0;
            
            if (this.getStudents && this.getStudents.length > 0) {
                this.getStudents.forEach(student => {
                    if (student.preparations) {
                        Object.values(student.preparations).forEach(preparation => {
                            const sessionPrice = this.getItemPrice(preparation, student.preparations);
                            price += sessionPrice;
                        });
                    }
                });
            }

            return price;
        },

        getItemPrice(session, studentSessions) {
            const getCurrentPrice = {};
            session.prices.forEach((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            const numberItems = Object.values(studentSessions).filter(element => {
                if (element.product_type_id !== 2900012 &&
                    element.start_date === session.start_date &&
                    element.location && session.location &&
                    element.location.address === session.location.address) 
                {
                    return element;
                }
            }).length;

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0;
            }
            else if (numberItems === 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0;
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT'];
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT'];
                } 
                else {
                    return 0;
                }
            }

            return 0;
        },


        async handleSubmitLead(route) {
            try {

                if (!this.hasPreparations) {
                    this.errorMessage = "Veuillez ajouter au moins une formation avant de continuer.";
                    return;
                }

                this.submitLoading = true
                const status = route === '/confirmation-inscription' ? LeadStatus.APPROVED : LeadStatus.NEEDS_REVIEW;

                const payload = {
                    email: this.getParent.email,
                    payload: this.$store.getters['payload/getPayload'],
                    status: status
                };

                await this.$store.dispatch('lead/submitLead', payload);

                this.createDatalayer('inscription_autre')

                await this.$store.dispatch('payload/clearPayload');
                await this.$store.dispatch('auth/clearApiTokenAndAuth');

                this.submitLoading = false
                this.$router.push(route);
            } catch (error) {
                console.error('Erreur lors de la soumission du lead:', error);
                if (error.response && error.response.data) {
                  this.errorMessage = error.response.data.message;
                } else {
                  this.errorMessage = "Une erreur s'est produite lors de votre inscription.";
                }
                this.submitLoading = false
            }
        },
    },

    watch: {
      getStudents: {
        handler() {
          // check if at least one student has a preparation
          this.hasPreparations = Object.values(this.getStudents).some(student =>
                student.preparations && Object.keys(student.preparations).length > 0
          );
          if (this.hasPreparations) {
            this.errorMessage = '';
          } else {
            this.errorMessage = "Veuillez ajouter au moins une formation avant de continuer.";
          }
        },
        deep: true
      }
  },
};
</script>

<style scoped>
@media (max-width: 576px) {
    .flex-md-row {
        flex-direction: column !important;
    }

    .justify-md-space-between {
        justify-content: flex-start !important;
    }

    .align-md-center {
        align-items: flex-start !important;
    }

    .align-items-start {
        align-items: flex-start !important;
    }
}
</style>