<template>
    <v-form ref="loginForm" @submit.prevent="validateLoginForm()">
        <v-row v-if="existingEmail" class="text-start px-3 mb-4">
            Un compte existe déjà avec cette adresse email. <br>Merci de vous connecter ou cliquer sur "mot de passe oublié" pour réinitialiser votre mot de passe.<br>
            En cas de difficulté, vous pouvez nous contacter au 01 42 05 41 36.
        </v-row>
        <v-row v-if="!existingEmail" class="text-start px-3 mb-4">
            <div>Pour savoir si vous avez déjà un compte :</div>
            <ul class="pl-8">
                <li>Cliquer sur mot de passe oublié puis saisir votre email.</li>
                <li>
                    Si « Aucun utilisateur n'a été trouvé avec cette adresse courriel. », cliquer sur 
                    <span @click="backToEditUser" class="link-back">Revenir au formulaire</span> 
                    et enregistrer vos coordonnées pour créer un compte.
                    </li>
            </ul>
        </v-row>
        <v-row class="px-3 py-2">
            <v-text-field v-model="email" label="Email" variant="outlined" :rules="emailRules" />
        </v-row>
        <v-row class="px-3 py-1">
            <v-text-field 
                v-model="password" 
                label="Mot de passe" 
                variant="outlined"
                :type="showPassword ? 'text' : 'password'"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                :rules="passwordRules"
                autocomplete="on"
            />
        </v-row>
        <v-row v-if="error" class="px-3 mb-5">
            <v-alert :text="reason" type="error" variant="tonal"></v-alert>
        </v-row>
        <v-row v-if="disabled" class="px-3 mb-5">
            <v-alert text="Votre compte a été désactivé, connexion impossible." type="error" variant="tonal"></v-alert>
        </v-row>
        <v-row class="px-3 mb-7">
            <a :href="intranetUrl + 'password/reset'" target="_blank" class="text-decoration-none text-start text-red-darken-1">Mot de passe oublié ?</a>
        </v-row>

        <v-row class="my-1">
            <v-col cols="12" class="d-flex justify-start">
                <div class="d-none d-sm-block w-100 mt-2">
                    <v-row justify="space-between" class="px-3">
                        <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold mb-2">
                            R<span class="text-lowercase">evenir au formulaire</span>
                            <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                        </v-btn>
                        <v-btn type="submit" color="primary" class="font-weight-bold">
                            S<span class="text-lowercase">e Connecter</span>
                            <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                        </v-btn>
                    </v-row>
                </div>
                
                <v-row class="px-3 mt-0 d-sm-none">
                    <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold w-100 mb-2">
                        R<span class="text-lowercase">etour</span>
                        <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                    </v-btn>
                    <v-btn type="submit" color="primary" class="font-weight-bold w-100">
                        S<span class="text-lowercase">e Connecter</span>
                        <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

        <v-overlay v-model="logUserLoading" class="d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
            >
            </v-progress-circular>
        </v-overlay>

    </v-form>
</template>

<script>

import { mapGetters } from 'vuex';

import { LeadStatus } from "@/constants/lead-status";

export default {
    data() {
        return {
            email: '',
            password: '',
            emailRules: [ 
                v => !!v || "L'email est requis",
                v => /.+@.+\..+/.test(v) || 'Format invalide'
            ],
            passwordRules: [ 
                v => !!v || "Le mot de passe est requis",
            ],
            logUserLoading: false,
            showPassword: false,
            error: false,
            disabled: false,
            existingEmail: false,
            intranetUrl: null,
            reason: 'Échec de la connexion',
        };
    },

    computed: {
        ...mapGetters('payload', ['getParent']),
    },

    methods: {

        async validateLoginForm () {
            const { valid } = await this.$refs.loginForm.validate()

            if(valid) {
                this.logUserLoading = true
                const params = {
                    'email': this.email,
                    'password': this.password,
                }
                this.$store.dispatch('auth/login', params)
                .then( async () => {

                    const payload = {
                        email: this.getParent.email, 
                        payload: this.$store.getters['payload/getPayload'],
                        status: LeadStatus.DROPPED_CHOICE
                    };

                    await this.$store.dispatch('lead/updateLead', payload);

                    window.location.href = '/module-inscription'
                })
                .catch((error) => {
                    this.error = true
                    this.logUserLoading = false
                    if (error.response?.data?.message) {
                      this.reason = error.response.data.message;
                    }
                })    
                
            }
        },

        backToEditUser() {
            this.$router.push({name: 'UserCreation'});
        },

        checkIfExistingEmail() {
            if (this.$route.query.existingEmail) {
                this.existingEmail = true;
                this.$router.replace({ query: {} });
            }
        },
    },

    created() {
        this.intranetUrl = process.env.VUE_APP_API_INTRANET
        this.checkIfExistingEmail()
    },

    watch: {

        email() {
            this.error = false;
            this.disabled = false;
        },

        password() {
            this.error = false;
            this.disabled = false;
        }
        
    },
};

</script>

<style scoped>

.link-back {
    color: rgb(14, 14, 250);
    font-weight: 500;
}

.link-back:hover {
    cursor: pointer;
}

</style>