<template>
    <v-row justify=center class="mt-15 mb-10">
        <v-col cols=8>
            <v-card v-if="hasParentData" class="rounded-xl py-5 px-10 mr-3 info-panel" flat color="white">
                <v-card-text class="text-start">

                    <div v-if="hasParentData">
                        <v-row>
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">RESPONSABLE</span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2">
                                <span v-if="user.ammon" class="font-weight-bold">{{ user.firstname }} {{ user.name }}</span>
                                <span v-else class="font-weight-bold">{{ user.firstname }} {{ user.lastname }}</span>
                            </v-col>
                        </v-row>
                    </div>
                        
                    <div v-if="hasStudentsData">
                        <v-row class="mt-3">
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">ÉLÈVE(S)</span>
                            </v-col>
                        </v-row>
                        <v-row v-for="(child, index) in children" :key="index" class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 pb-0">
                                <span class="font-weight-bold">{{ child.firstname }} {{ child.lastname }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0 pb-3">
                                <span class="child-class">{{ child?.class?.label }}</span>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="hasPreparations" class="mt-3">
                        <v-row>
                            <v-col cols=12>
                                <span class="part-title font-weight-bold">VOTRE DEMANDE D'INSCRIPTION</span>
                            </v-col>
                        </v-row>
                        <div v-for="(child, childIndex) in children" :key="childIndex" class="mt-3 mb-6">
                            <div class="font-weight-bold text-primary mb-5">Pour {{ child.firstname }} {{ child.lastname }}</div>
                            <v-row v-for="(session, preparationId) in child.preparations" :key="preparationId" class="pb-1">
                                <v-col cols=12 class="d-flex justify-space-between py-0">
                                    <div>
                                        <span class="font-weight-bold">{{ session.product_name }}</span>
                                    </div>
                                    <div class="d-flex">
                                        <span class="font-weight-bold item-price">{{ getItemPrice(session, child.preparations) }}€</span>
                                        <v-icon v-if="$route.name != 'Payment'" @click="removePreparation(session.id_ammon, childIndex)" icon="mdi-trash-can-outline" class="ml-1 trash-icon" size="x-small" color="primary"></v-icon>
                                    </div>
                                </v-col>
                                <v-col cols=12 class="py-0">
                                </v-col>
                                <v-col cols=12 class="py-0">
                                    <span class="session-infos">
                                        Du {{ formatDate(session.start_date_displayed || session.start_date) }} 
                                        au {{ formatDate(session.end_date_displayed || session.end_date) }}
                                    </span>
                                </v-col>
                                <v-col cols=12 class="py-0">
                                    <span v-if="session.location" class="session-infos">{{ session.location.description }} ({{ session.location.city }} {{ session.location.postcode }})</span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-if="totalPrice > 0" justify="space-between" class="px-3 my-5">
                            <span class="font-weight-bold">Total</span>
                            <span class="font-weight-bold">{{ totalPrice }}€</span>
                        </v-row>
                        <v-row class="px-3">
                            <router-link to="summary" v-if="$route.name == 'ModuleInscription'" class="validation-link">
                                <v-btn class="font-weight-bold d-none d-lg-block" color="secondary">
                                    S<span class="text-lowercase">uivant</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                                <v-btn class="font-weight-bold d-lg-none" color="secondary">
                                    S<span class="text-lowercase">uivant</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link @click="handleSubmitLead('/confirmation-inscription')" to="" v-if="$route.name == 'Summary'" class="validation-link mb-3">
                                <v-btn class="font-weight-bold d-none d-lg-block" color="secondary">
                                    C<span class="text-lowercase">onfirmer cette inscription</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                                <v-btn class="font-weight-bold d-lg-none" color="secondary">
                                    C<span class="text-lowercase">onfirmer</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link @click="handleSubmitLead('/confirmation-recall')" to="" v-if="$route.name == 'Summary'" class="recall-link text-primary">
                                    Vous hésitez ? Faites vous rappeler
                            </router-link>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>

        <v-dialog v-model="showInfoPanel" class="info-panel-mobile">
            <v-card v-if="hasParentData" class="rounded-xl py-3 px-3" flat color="white">
                <v-card-text class="text-start">

                    <div v-if="hasParentData">
                        <v-row>
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">RESPONSABLE</span>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 line-height-mobile">
                                <span v-if="user.ammon" class="font-weight-medium user-name-mobile">{{ user.firstname }} {{ user.name }}</span>
                                <span v-else class="font-weight-medum">{{ user.firstname }} {{ user.lastname }}</span>
                            </v-col>
                        </v-row>
                    </div>
                        
                    <div v-if="hasStudentsData">
                        <v-row class="mt-3">
                            <v-col cols=12 class="pb-2">
                                <span class="part-title font-weight-bold">ÉLÈVE(S)</span>
                            </v-col>
                        </v-row>
                        <v-row v-for="(child, index) in children" :key="index" class="mt-0">
                            <v-col cols=12 class="pb-0 pt-2 pb-0 line-height-mobile">
                                <span class="font-weight-medium child-name-mobile">{{ child.lastname }} {{ child.firstname }}</span>
                            </v-col>
                            <v-col cols=12 class="pt-0 pb-3 line-height-mobile">
                                <span class="child-class">{{ child?.class?.label }}</span>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="hasPreparations" class="mt-3">
                        <v-row>
                            <v-col cols=12>
                                <span class="part-title font-weight-bold">VOTRE DEMANDE D'INSCRIPTION</span>
                            </v-col>
                        </v-row>
                        <div v-for="(child, childIndex) in children" :key="childIndex" class="mt-3 mb-6">
                            <div class="font-weight-medium text-primary child-name-mobile mb-5">Pour {{ child.firstname }} {{ child.lastname }}</div>
                            <v-row v-for="(session, preparationId) in child.preparations" :key="preparationId" class="pb-1">
                                <v-col cols=12 class="d-flex justify-space-between py-0 line-height-mobile mb-1">
                                    <div>
                                        <span class="font-weight-bold product-mobile">{{ session.product_name }}</span>
                                    </div>
                                    <div class="d-flex">
                                        <span class="font-weight-bold price-mobile item-price">{{ getItemPrice(session, child.preparations) }}€</span>
                                        <v-icon v-if="$route.name != 'Payment'" @click="removePreparation(session.id_ammon, childIndex)" icon="mdi-trash-can-outline" class="ml-1 trash-icon" size="x-small" color="primary"></v-icon>
                                    </div>
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                    <span class="session-infos">
                                        Du {{ formatDate(session.start_date_displayed || session.start_date) }} 
                                        au {{ formatDate(session.end_date_displayed || session.end_date) }}
                                    </span>
                                </v-col>
                                <v-col cols=12 class="py-0 line-height-mobile">
                                    <span v-if="session.location" class="session-infos">{{ session.location.description }} ({{ session.location.city }} {{ session.location.postcode }})</span>
                                </v-col>
                            </v-row>
                        </div>
                        <v-row v-if="totalPrice > 0" justify="space-between" class="px-3 my-5">
                            <span class="font-weight-bold total-mobile">Total</span>
                            <span class="font-weight-bold price-mobile">{{ totalPrice }}€</span>
                        </v-row>
                        <v-row class="px-3 mb-1">
                            <router-link v-if="$route.name == 'ModuleInscription'" to="summary" @click="showInfoPanel = false">
                                <v-btn class="font-weight-normal" color="secondary">
                                    S<span class="text-lowercase">uivant</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link @click="handleSubmitLead('/confirmation-inscription')" v-if="$route.name == 'Summary'" to="" class="mb-3">
                                <v-btn class="font-weight-normal w-100" color="secondary">
                                    C<span class="text-lowercase">onfirmer</span>
                                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                                </v-btn>
                            </router-link>
                            <router-link @click="handleSubmitLead('/confirmation-recall')" v-if="$route.name == 'Summary'" to="recall" class="text-primary">
                                Vous hésitez ? Faites vous rappeler
                            </router-link>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay v-model="submitLoading" class="d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
            >
            </v-progress-circular>
        </v-overlay>

    </v-row>
</template>

<script>

import { mapGetters } from 'vuex';
import moment from 'moment';

import { LeadStatus } from "@/constants/lead-status";

export default {
    data() {
        return {
            children: null,
            submitLoading: false,
            user: null,
        }
    },

    computed: {
        ...mapGetters('payload', ['getParent', 'getPayload', 'getStudents']),

        showInfoPanel: {
            get() {
                return this.$store.getters.getShowInfoPanel;
            },
            set(value) {
                if (value) {
                    this.$store.dispatch('showInfoPanel');
                } else {
                    this.$store.dispatch('unShowInfoPanel');
                }
            }
        },

        hasParentData() {
            return this.getParent !== null && Object.keys(this.getParent).length > 0;
        },

        hasStudentsData() {
            return this.getStudents !== null && Object.keys(this.getStudents).length > 0;
        },

        hasPreparations() {
            if (!this.getStudents) return false;
            return Object.values(this.getStudents).some(student => 
                student.preparations && Object.keys(student.preparations).length > 0
            );
        },

        sectors() {
            var sectors = "";
            Object.values(this.getStudents).forEach(student => {
                if (student.preparations) {
                    Object.values(student.preparations).forEach(preparation => {
                        const productName = preparation.product_name;
                        if (sectors.length > 0) {
                            if (!sectors.includes(productName)) {
                                sectors += ", " + productName;
                            }
                        } else {
                            sectors = productName;
                        }
                    });
                }
            });
            return sectors;
        },

        totalPrice() {
            let price = 0;
            Object.values(this.getStudents).forEach(student => {
                if (student && student.preparations) {
                    Object.values(student.preparations).forEach(session => {
                        price += parseInt(this.getItemPrice(session, student.preparations));
                    });
                }
            });
            return price;
        }
    },

    methods: {
        backToEditUser() {
            this.$router.push({ name: 'UserCreation' });
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        createDatalayer(event) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': event,
                'email': event,
                'user': 'new_user',
                'new_customer': true,
                'filière': this.sectors,
                'amount': this.calculateTotalPrice()
            })
        },

        calculateTotalPrice() {
            let price = 0;
            
            if (this.getStudents && this.getStudents.length > 0) {
                this.getStudents.forEach(student => {
                    if (student.preparations) {
                        Object.values(student.preparations).forEach(preparation => {
                            const sessionPrice = this.getItemPrice(preparation, student.preparations);
                            price += sessionPrice;
                        });
                    }
                });
            }

            return price;
        },

        getItemPrice(session, studentSessions) {
            const getCurrentPrice = {};
            session.prices.forEach((priceItem) => {
                const startDate = moment(priceItem.start_date).startOf('day');
                const endDate = moment(priceItem.end_date).startOf('day');
                const currentDate = moment().startOf('day');
                const checkDate = currentDate.isBetween(startDate, endDate, null, '[]');

                if (priceItem.end_date && checkDate) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                } else if (!priceItem.end_date && (startDate <= currentDate)) {
                    getCurrentPrice[priceItem.type] = parseInt(priceItem.amount_ttc);
                }
            });

            if ('AUTO1' in getCurrentPrice) {
                return getCurrentPrice['AUTO1'];
            }

            const numberItems = Object.values(studentSessions).filter(element => {
                if (element.product_type_id !== 2900012 &&
                    element.start_date === session.start_date &&
                    element.location && session.location &&
                    element.location.address === session.location.address) 
                {
                    return element;
                }
            }).length;

            if (numberItems < 2) {
                return '1MAT' in getCurrentPrice ? getCurrentPrice['1MAT'] : 0;
            }
            else if (numberItems === 2) {
                return '2MAT' in getCurrentPrice ? getCurrentPrice['2MAT'] : 0;
            }
            else if (numberItems > 2) {
                if ('3MAT' in getCurrentPrice) {
                    return getCurrentPrice['3MAT'];
                }
                else if ('2MAT' in getCurrentPrice) {
                    return getCurrentPrice['2MAT'];
                } 
                else {
                    return 0;
                }
            }

            return 0;
        },

        async handleSubmitLead(route) {
            try {
                this.submitLoading = true
                const status = route === '/confirmation-inscription' ? LeadStatus.APPROVED : LeadStatus.NEEDS_REVIEW;

                const payload = {
                    email: this.getParent.email,
                    payload: this.$store.getters['payload/getPayload'],
                    status: status
                };
                this.showInfoPanel = false

                await this.$store.dispatch('lead/submitLead', payload);

                this.createDatalayer('inscription_autre')
                
                await this.$store.dispatch('payload/clearPayload');
                await this.$store.dispatch('auth/clearApiTokenAndAuth');

                this.submitLoading = false
                this.$router.push(route);
            } catch (error) {
                console.error('Erreur lors de la soumission du lead:', error);
            }
        },

        initializePanelData() {
            if (this.getParent !== null) {
                this.user = this.getParent
            }
            if (this.getStudents !== null) {
                this.children = this.getStudents
            }
        },

        removePreparation(sessionIdAmmon, studentIndex) {
            this.$store.dispatch('payload/removeStudentPreparation', {
                studentIndex: studentIndex,
                preparationId: sessionIdAmmon
            });
        },
    },

    created() {
        this.initializePanelData()
    },

}

</script>

<style scoped>

.info-panel {
    z-index: 10;
}

.part-title {
    color: #10757a;
    font-size: 14px;
}

.session-infos, .parent-infos, .child-class {
    font-size: 13px;
    color: #6B6C80;
}

.info-panel-mobile {
    max-width: 400px;
}

.user-name-mobile, .child-name-mobile, .product-mobile, .price-mobile, .total-mobile {
    font-size: 14px;
}

.line-height-mobile {
    line-height: 17px;
}

.recall-link {
    font-size: 16px;
}

.validation-link {
    text-decoration: none;
}

.trash-icon, .item-price {
    align-self: center;
}

</style>