<template>
    <div>
        <div v-for="(child, index) in children" :key="index">
            <v-form ref="childForm">
                <v-row class="d-flex align-center text-start text-primary font-weight-bold text-h6 px-3 mt-8 mb-6">
                    Coordonnées de l'élève
                    <v-icon 
                        v-if="children.length > 1" 
                        @click="removeChild(index)" 
                        class="ml-2" 
                        size="x-small"
                    >
                        mdi-trash-can
                    </v-icon>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="4" xl="2" class="py-2">
                        <select-field 
                            v-model="child.gender" 
                            label="Civilité" 
                            :rules="genderRules"
                            :items="genderOptions" 
                            item-title="text"
                            item-value="value"
                        ></select-field>
                    </v-col>
                    <v-col cols="12" sm="8" xl="5" class="py-2">
                        <text-field v-model="child.firstname" label="Prénom" :rules="firstNameRules"></text-field>
                    </v-col>
                    <v-col cols="12" xl="5" class="py-2">
                        <text-field v-model="child.lastname" label="Nom" :rules="lastNameRules"></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <text-field-phone 
                            v-model="phoneInputs[index]" 
                            label="Téléphone (obligatoire)" 
                            :rules="phoneRules"
                            @input="(value) => handlePhoneInput(value, index)"
                        ></text-field-phone>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <text-field v-model="child.email" label="Email (optionnel)" :rules="emailRules"></text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <v-autocomplete
                            v-model="child.highSchool" 
                            label="Établissement scolaire (optionnel)" 
                            placeholder="Tapez les premières lettres"
                            :items="highSchools"
                            item-text="caption"
                            :returnObject="true"
                            no-data-text="Aucun résultat trouvé"
                            variant="outlined"
                            @input="handleSearchChange($event)"
                            :custom-filter="compareSearchText"
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <select-field-with-title 
                            v-model="child.class" 
                            label="Classe à la rentrée 2024 (obligatoire)" 
                            :items="classes"
                            :itemTitle="'label'"
                            :rules="classRules"
                            :returnObject="true"
                            @update:modelValue="getGenericClasses($event.id_ammon, index)"
                        ></select-field-with-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm=6 xl=4 class="py-2" v-for="(speciality, key) in specialties[index]" :key="key">
                        <select-field-with-title
                            v-model="child.specialties[key]" 
                            :label="getoptionName(child) + ' ' + (key+1) + ' (optionnel)'"
                            :items="filterSpecialties(speciality, index)"
                            :itemTitle="'caption'"
                            :return-object="true"
                        ></select-field-with-title>
                    </v-col>
                    <v-col v-if="child.class && loading" class="pt-0 mb-3">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <select-field-with-title 
                            v-model="child.level" 
                            label="Niveau de l'élève (recommandé)" 
                            :items="levelOptions"
                            :itemTitle="'libelle'"
                        ></select-field-with-title>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2">
                        <text-area v-model="child.observation" label="Observations (recommandé)"></text-area>
                    </v-col>
                </v-row>
            </v-form>
        </div>

        <v-row>
            <v-col cols="12" class="py-2 mb-3 d-flex justify-start">
                <v-btn @click="addNewChild" class="font-weight-bold" color="grey-lighten-4">
                    Ajouter un autre enfant
                    <v-icon icon="mdi-plus" class="ml-1"></v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-row >
            <v-col cols="12" class="d-flex justify-start">
                <div class="d-none d-sm-block w-100 mt-2">
                    <v-row justify="space-between" class="px-3">
                        <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold mb-2">
                            R<span class="text-lowercase">etour</span>
                            <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                        </v-btn>
                        <v-btn @click="validateAddChildForm" color="primary" class="font-weight-bold">
                            S<span class="text-lowercase">uivant</span>
                            <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                        </v-btn>
                    </v-row>
                </div>

                <v-row class="px-3 mt-0 d-sm-none">
                    <v-btn @click="backToEditUser" color="grey-lighten-4" class="font-weight-bold w-100 mb-2">
                        R<span class="text-lowercase">etour</span>
                        <v-icon icon="mdi-arrow-left" class="ml-1"></v-icon>
                    </v-btn>
                    <v-btn @click="validateAddChildForm" color="primary" class="font-weight-bold w-100">
                        S<span class="text-lowercase">uivant</span>
                        <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </v-row>

        <v-dialog v-model="showMailError" max-width="600">
            <v-card>
                <v-card-text>
                    Il est recommandé d’indiquer le mail de l’élève ET celui du responsable (ils doivent être différents).<br>
                    Le mail de l’élève pourra être renseigné plus tard.
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="showMailError = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>

import moment from 'moment';
import { mapGetters } from 'vuex';

import SelectField from '@/components/form-base/SelectFieldBase.vue';
import SelectFieldWithTitle from '@/components/form-base/SelectFieldWithTitleBase.vue';
import TextField from '@/components/form-base/TextFieldBase.vue';
import TextFieldPhone from '@/components/form-base/TextFieldPhoneBase.vue';
import TextArea from '@/components/form-base/TextAreaBase.vue';

import { LeadStatus } from "@/constants/lead-status";
import { ValidationRules} from "@/constants/validation-rules";

export default {

    components: {
        SelectField,
        SelectFieldWithTitle,
        TextField,
        TextFieldPhone,
        TextArea
    },

    data() {
        return {
            formIsValid: true,
            searchInput: '',
            loading: true,
            showMailError: false,
            phoneInputs: [''],
            children: [{
                gender: null,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                mobilePhone: '',
                level: null,
                observation: '',
                class: null,
                specialties: [],
            }],
            levelOptions: [
                {'libelle': 'Excellent', 'value': '1'},
                {'libelle': 'Bon', 'value': '2'},
                {'libelle': 'Moyen', 'value': '3'},
                {'libelle': 'Fragile', 'value': '4'},
                {'libelle': 'Indéfini', 'value': '9'}
            ],
            genderOptions: [
                { text: 'M.', value: '1' },
                { text: 'Mme', value: '2' }
            ],
            classes: [],
            highSchools: [],
            specialties: [],
            genderRules: [ 
                v => !!v || "La civilité est requise",
            ],
            classRules: [ 
                v => !!v || "La classe est requise",
            ],
            emailRules: [ 
                v => !v || (ValidationRules.EMAIL_REGEX.test(v) && this.isUniqueEmail(v)) || 'Format invalide ou adresse email déjà saisie'
            ],
            firstNameRules: [ 
                v => !!v || "Le prénom est requis",
            ],
            lastNameRules: [ 
                v => !!v || "Le nom est requis",
            ],
            phoneRules: [
                v => !!v || "Le numéro de téléphone est requis",
            ],
            optionNames: {
                'Spécialité': [1400248, 1400249],
                'Filiaire': [1400250, 1400251],
                'Option': [1400252, 1400253]
            }
        };
    },

    computed : {

        ...mapGetters('payload', ['getParent', 'getStudents']),

    },

    methods: {

        addNewChild() {
            this.children.push({
                gender: null,
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                mobilePhone: '',
                level: null,
                observation: '',
                class: null,
                specialties: [],
            });
            this.specialties[this.children.length - 1] = [];
        },

        removeChild(index) {
            this.children.splice(index, 1);
            this.specialties.splice(index, 1);
        },

        isUniqueEmail(value) {

          let existsInParent = this.getParent.email.toLowerCase() === value.toLowerCase()
          let existsInChildren = false;

          let index = this.children.findIndex(child => child.email.toLowerCase() === value.toLowerCase());

          if (this.children.length > 1) {
              existsInChildren = this.children.some((child, i) => {
                return i !== index && child.email.toLowerCase() === value.toLowerCase();
              });
          }

          return !(existsInParent || existsInChildren);

        },

        handlePhoneInput(event, index) {
            const value = event?.target?.value;

            this.children[index].phone = '';
            this.children[index].mobilePhone = '';

            if (value == '') {
                this.phoneInputs[index] = '';
                return;
            }

            const cleanNumber = value.replace(/[^0-9]/g, '');
            
            if (cleanNumber.startsWith('06') || cleanNumber.startsWith('07')) {
                this.children[index].mobilePhone = cleanNumber;
            } else {
                this.children[index].phone = cleanNumber;
            }
            
            this.phoneInputs[index] = value;        
        },

        backToEditUser() {
            this.$router.push({name: 'UserCreation'});
        },

        compareSearchText(itemText, queryText) {
            const normalizedItemText = this.removeAccents(itemText.toLowerCase());
            const normalizedQueryText = this.removeAccents(queryText.toLowerCase());
            return normalizedItemText.includes(normalizedQueryText);
        },

        filterSpecialties(specialties, childIndex) {
            return specialties.filter(speciality => {
                return !this.children[childIndex].specialties.some(childSpecialty => childSpecialty.code == speciality.code);
            });
        },

        getClassesConstants() {
            this.$store.dispatch('getClassesConstants')
            .then(response => {
                this.classes = response

            })
            .catch(error => console.log(error));
        },

        getGenericClasses(value, childIndex) {
            this.loading = true
            this.children[childIndex].specialties = []
            this.specialties[childIndex] = [];
            this.$store.dispatch('getGenericClasses', value)
            .then(response => {
                this.children[childIndex].genericClass = response;
                this.getSpecialties(value, childIndex);
            })
            .catch(error => console.log(error));
        },

        getHighSchools(search) {
            this.$store.dispatch('getHighSchools', search)
            .then(response => {
                this.highSchools = response.slice(0, 20).map(item => {
                    return {title: item.name, code: item.code}
                });
            })
            .catch(error => console.log(error));
        },

        getoptionName(child) {
            for (const [key, value] of Object.entries(this.optionNames)) {
                if (value.includes(child.class.id_ammon)) {
                    return key;
                }
            }
            return 'Spécialité'
        },

        getSpecialties(classId, childIndex) {
            this.$store.dispatch('getSpecialties', classId)
            .then(specialties => {
                this.specialties[childIndex] = specialties.map(subArray => {
                    return subArray.map(specialty => ({
                        id: specialty.id,
                        code: specialty.code,
                        caption: specialty.caption,
                    }));
                });
                this.loading = false
            })
            .catch(error => {
                console.log(error)
                this.loading = false;
            });
        },

        getStudentYear() {
            var currentYear = null
            var currentMonth = moment().month();
            if (currentMonth >= 5) {
                currentYear = moment().year();
            } else { 
                currentYear = moment().subtract(1, 'year').year();
            }
            return currentYear
        },

        handleSearchChange(value) {
            this.getHighSchools(value.target.value)
        },

        initializeFormData() {
            if (this.getStudents.length > 0) {
                this.children = this.getStudents.map((student, index) => {
                    this.specialties[index] = [];
                    if (student.class) {
                        this.getSpecialties(student.class.id_ammon, index);
                    }

                    if (student.mobilePhone) {
                        this.phoneInputs[index] = student.mobilePhone;
                    } else if (student.phone) {
                        this.phoneInputs[index] = student.phone;
                    }

                     return {
                        gender: student.gender || null,
                        firstname: student.firstname || '',
                        lastname: student.lastname || '',
                        email: student.email || '',
                        phone: student.phone || '',
                        level: student.level || null,
                        observation: student.observation || '',
                        class: student.class || null,
                        specialties: student.specialties || [],
                    };
                });
            }
        },

        removeAccents(text) {
            return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        },

        async validateAddChildForm() {
            try {
                const formRefs = Array.isArray(this.$refs.childForm) 
                    ? this.$refs.childForm 
                    : [this.$refs.childForm];
                
                const validationResults = await Promise.all(
                    formRefs.map(form => form.validate())
                );
                
                const isValid = validationResults.every(result => result.valid);

                if (isValid) {
                    this.children.forEach((child, index) => {
                        if (index < this.getStudents.length) {
                            this.$store.dispatch('payload/updateStudent', { index, data: child });
                        } else {
                            this.$store.dispatch('payload/addStudent', child);
                        }
                    });

                    const payload = {
                        email: this.getParent.email,
                        payload: this.$store.getters['payload/getPayload'],
                        status: LeadStatus.DROPPED_STUDENT
                    };

                    await this.$store.dispatch('lead/updateLead', payload);

                    this.$router.push({ name: 'ModuleInscription' });
                }
            } catch (error) {
                console.error('Erreur lors de la validation:', error);
            }
        },

    },

    created() {
        this.initializeFormData()
        this.getClassesConstants()
        this.getHighSchools()
    },

};

</script>

<style scoped>

.spinner {
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #272954;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

</style>