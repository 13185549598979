<template>
    <v-row class="px-3 mt-5 flex-column" style="width: 100%">
        <div class="d-flex justify-space-between align-start w-100" :class="{'flex-column': $vuetify.display.xs, 'gap-4': $vuetify.display.xs}">
            <a 
                href="https://www.cours-thales.fr/contact/demande-rappel"
                target="_blank"
                class="text-primary mb-3"
            >
                <span>Vous hésitez ? Faites vous rappeler</span>
            </a>
            <v-btn 
                @click="handleSummaryNavigation" 
                class="font-weight-normal" 
                color="primary"
                :disabled="!hasPreparations"
            >
                S<span class="text-lowercase">uivant</span>
                <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
            </v-btn>
        </div>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { LeadStatus } from "@/constants/lead-status";

export default {
    computed: {
        ...mapGetters('payload', ['getParent', 'getPayload', 'getStudents']),
    },

    data() {
        return {
            hasPreparations: false,
        };
    },

    watch: {
      getStudents: {
        handler() {
          // check if at least one student has a preparation
          this.hasPreparations = Object.values(this.getStudents).some(student =>
                student.preparations && Object.keys(student.preparations).length > 0
            );
        },
        deep: true
      }
    },
    
    methods: {

        async handleSummaryNavigation() {
            try {
                const payload = {
                    email: this.getParent.email,
                    payload: this.getPayload,
                    status: LeadStatus.DROPPED_SUMMARY
                };
                
                await this.$store.dispatch('lead/updateLead', payload);
                
                this.$router.push({ name: 'Summary' });
            } catch (error) {
                console.error('Erreur lors de la mise à jour du lead:', error);
            }
        }
    }
};
</script>