export const LeadStatus = Object.freeze({
  DRAFT: 0,
  PENDING: 1,
  APPROVED: 2,
  DROPPED_STUDENT: 3,
  DROPPED_CHOICE: 4,
  DROPPED_SUMMARY: 5,
  NEEDS_REVIEW: 6,
  CALLED: 7,
  APPROVED_MANUALLY: 8,
  DROPPED_MANUALLY: 9,
  APPROVED_WITH_ERRORS: 10,
  NEEDS_REVIEW_WITH_ERRORS: 11
});