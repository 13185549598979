<template>
    <v-card v-if="showCard" class="rounded-xl pa-5" flat :color="isSessionSelected ? 'quaternary' : '#F6F6F9'">

        <v-card-title>
            <div class="d-flex align-center">
                <div class="font-weight-bold title">{{ product.name }}</div>
            </div>
        </v-card-title>

        <v-card-text class="text-start pb-0">
            <div class="mt-2 mb-2 font-weight-bold subtitle-description">{{ getProductSubtitleDescription(product) }}</div>
            <div class="mt-2 mb-2 font-weight-bold">{{ getProductShortDescription(product) }}</div>
            <p class="description text-black pl-4" v-html="getProductFullDescription(product)"></p>
        </v-card-text>

        <v-card-actions >
            <v-row v-if="(Object.values(filteredActionsByPeriod(product.actions)).length > 0 && product.type_id != typeCourseFormule) || (product.type_id == typeCourseFormule && product.formule_type == this.typeFormulePlusStage)">
              <v-col cols=12 class="mb-3">
                    <select-field-with-title
                        v-model="selectedPeriod" 
                        label="Période(s)" 
                        class="select-session d-sm-none"
                        :items="getPeriodsCaptions(product)"
                        itemTitle="caption"
                        :returnObject="true"
                        :hide-details="true"
                        @update:modelValue="displayPeriods($event)"
                    ></select-field-with-title>
                    <select-field-with-title
                        v-model="selectedPeriod" 
                        label="Choisir une période" 
                        class="select-session d-none d-sm-block"
                        :items="getPeriodsCaptions(product)"
                        itemTitle="caption"
                        :hide-details="true"
                        :returnObject="true"
                        @update:modelValue="displayPeriods($event)"
                    ></select-field-with-title>
                </v-col>
                <v-row v-if="Object.keys(sessionsLocation).length > 0 && !isSessionSelected" class="pl-1 mb-6">
                    <v-col v-for="(value, location) in sessionsLocation" :key="location" cols="auto" class="py-0 custom-col">
                        <v-checkbox v-model="sessionsLocation[location]" :label="location" hide-details></v-checkbox>
                    </v-col>
                </v-row>
                <v-row v-if="isSessionSelected" class="px-7 pt-4 pb-6">
                    <span class="font-weight-bold text-green">Formation ajoutée à votre sélection pour cette période.</span>
                </v-row>
                <v-row v-else class="px-3 mb-1">

                    <v-col cols=12 v-for="(filteredSession, index) in filteredSessions" :key="index">
                        <v-row class="px-3" justify="space-between" align="center">
                            <div class="d-flex flex-column text-start">
                                <span class="font-weight-bold session-label">{{ filteredSession.label }}</span>
                                <span class="session-date">
                                    Du {{ formatDate(filteredSession.start_date_displayed || filteredSession.start_date) }} 
                                    au {{ formatDate(filteredSession.end_date_displayed || filteredSession.end_date) }}
                                </span>
                                <span v-if="filteredSession.location && filteredSession.location.description" class="session-location">{{ filteredSession.location.description }}</span>
                            </div>
                            <div v-if="filteredSession.is_closed" class="text-red full-text d-none d-sm-block">Inscriptions clôturées</div>
                            <div v-if="filteredSession.is_closed" class="text-red full-text mt-2 w-100 d-sm-none">Inscriptions clôturées</div>
                            <v-btn v-if="!filteredSession.is_closed" @click="addToStudentPreparations(filteredSession)" class="font-weight-bold reservation-btn d-none d-sm-block" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                            <v-btn v-if="!filteredSession.is_closed" @click="addToStudentPreparations(filteredSession)" class="font-weight-bold reservation-btn mt-2 ml-0 w-100 d-sm-none" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                        </v-row>
                        <v-divider v-if="index+1 < Object.keys(filteredSessions).length" class="mt-6"></v-divider>
                    </v-col>
                </v-row>
            </v-row>
            <v-row v-if="product.type_id === typeCourseFormule && product.formule_type != this.typeFormulePlusStage && !isSessionSelected">
                <v-col cols=12 v-for="(filteredSession, index) in filterFormule(product.actions)" :key="index">
                    <div class="d-none d-sm-block">
                        <v-row class="px-3" justify="space-between" align="center">
                            <v-col cols=8 class="d-flex flex-column text-start">
                                <span class="session-location font-weight-bold text-body-2">{{ filteredSession.label }}</span>
                            </v-col>
                            <v-col cols=4 class="py-1">
                                <v-btn @click="addToStudentPreparations(filteredSession)" size="small" class="font-weight-bold reservation-btn" color="secondary" :disabled="isSessionSelected">
                                    R<span class="text-lowercase">éserver</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="d-sm-none">
                        <v-row class="px-5 mt-1" justify="space-between" align="center">
                            <div class="d-flex flex-column text-start">
                                <span class="session-location font-weight-bold text-body-2">{{ filteredSession.label }}</span>
                            </div>
                            <v-btn @click="addToStudentPreparations(filteredSession)" size="small" class="font-weight-bold reservation-btn mt-2 ml-0 w-100" color="secondary" :disabled="isSessionSelected">
                                R<span class="text-lowercase">éserver</span>
                            </v-btn>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="product.type_id === typeCourseFormule && product.formule_type != this.typeFormulePlusStage && isSessionSelected" class="px-5 py-3">
                 <span class="font-weight-bold text-green">Formation ajoutée à votre sélection.</span>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>

import _groupBy from "lodash/groupBy";
import moment from 'moment';

import { mapGetters } from 'vuex';

import SelectFieldWithTitle from '@/components/form-base/SelectFieldWithTitleBase.vue';

import { LeadStatus } from "@/constants/lead-status";

export default {

    components: {
        SelectFieldWithTitle,
    },

    props: {
        periodCaptions: Array,
        product: Object,
        selectedChild: Object,
    },

    data() {
        return {
            descriptionProductSubtitleCode: 1800767,
            descriptionProductCode: 2900004,
            filteredSessions: null,
            priorityCodeChoice: 'DATEAUCHOIX',
            sessionsLocation: {},
            selectedPeriod: null,
            sessions: null,
            typeCourseUnit: 2900019,
            typeCourseFormule: 2900012,
            typeFormulePlusStage: 80001
        };
    },

    computed: {

        ...mapGetters('payload', ['getParent', 'getStudents']),

        isSessionSelected() {
            const studentIndex = this.selectedChild.index;
            const student = this.getStudents[studentIndex];

            if (!student || !student.preparations) {
                return false;
            }

            if (this.product.type_id == this.typeCourseFormule && this.product.formule_type != this.typeFormulePlusStage) {
                return Object.keys(student.preparations).some(preparationId => {
                    const preparation = student.preparations[preparationId];
                    return preparation.product_id == this.product.id_ammon;
                });
            } else {
                return Object.keys(student.preparations).some(preparationId => {
                    const preparation = student.preparations[preparationId];
                    return preparation.product_id == this.product.id_ammon && this.selectedPeriod && this.selectedPeriod.code.includes(preparation.period);
                });
            }
        },

        showCard() {
            var actionsToShow = Object.values(this.product.actions).filter(action => {
                return action.type_id != this.typeCourseUnit && !action.invisible_inscription_form
            })
            return actionsToShow.length > 0 ? true : false
        },
    },

    methods: {

        addToStudentPreparations(session) {

            if (!this.selectedChild) {
                return;
            }

            const studentIndex = this.selectedChild.index;
            const student = this.getStudents[studentIndex];

            const updatedPreparations = {
                ...student.preparations,
                [session.id_ammon]: {
                    id_ammon: session.id_ammon,
                    start_date: session.start_date,
                    end_date: session.end_date,
                    class: session.class,
                    period: session.period,
                    location: session.location,
                    descriptions: this.product.descriptions,
                    prices: this.product.prices,
                    product_id: session.product_id,
                    product_name: this.product.name,
                    product_type_id: this.product.type_id,
                    type_id: session.type_id
                }
            };
            this.$store.dispatch('payload/addStudentPreparation', { studentIndex, updatedPreparations });

             const payload = {
                email: this.getParent.email,
                payload: this.$store.getters['payload/getPayload'],
                status: LeadStatus.DROPPED_CHOICE
            };

            this.$store.dispatch('lead/updateLead', payload);
        },

        displayPeriods(event) {
            this.sessionsLocation = {}
            this.sessions = event.actions
            if(this.sessions.length > 4) {
                this.getSessionsLocation()
                this.filterSessions()
            } else {
                this.filteredSessions = this.sessions
            }
        },

        filteredActionsByPeriod(actions) {
            const groupedActions = actions.filter((item) => {
                    return item.period !== undefined
                }
            );
            return _groupBy(groupedActions, "priorityCode");
        },

        filterFormule(actions) {
            return actions.filter((action) => {
                return !action.invisible_inscription_form
            })
        },

        filterSessions() {
            this.filteredSessions = this.sessions.filter((action) => {
                return action.location && action.location.description && this.sessionsLocation[action.location.description];
            });
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },

        getPeriodsCaptions(product) {

            const currentDate = moment();

            const actionsByPriorityAndYear = product.actions.reduce((acc, action) => {
                if(action.type_id != this.typeCourseUnit && !action.invisible_inscription_form) {

                    const momentStartDate = moment(action.start_date);

                    if (momentStartDate.isAfter(currentDate)) {
                        let year = momentStartDate.year();
                        if (momentStartDate.isBetween(`${year}-01-01`, `${year}-01-15`, null, '[]')) {
                            year = momentStartDate.subtract(1, 'year').year();
                        }
                        const priorityAndYear = action.period == this.priorityCodeChoice ? action.period : `${action.period} ${year}`
                        if (!acc[priorityAndYear]) {
                            acc[priorityAndYear] = [action];
                        } else {
                            acc[priorityAndYear].push(action);
                        }
                    }
                }
                return acc;
            }, {});

            let sortedKeys = [];

            for (let key in actionsByPriorityAndYear) {
                const firstStartDate = actionsByPriorityAndYear[key][0].start_date;
                const startDate = moment(firstStartDate).toDate();
                const tempObj = {
                    key,
                    startDate,
                };
                sortedKeys.push(tempObj);
            }

            sortedKeys.sort((a, b) => a.startDate - b.startDate);

            let sortedObject= {};
            for (let obj of sortedKeys) {
                sortedObject[obj.key] = actionsByPriorityAndYear[obj.key]
            }

            return Object.keys(sortedObject).map(key => {
                const caption = this.periodCaptions.find(period => key.includes(period.code))
                if (caption === undefined) {
                    return '';
                }
                return {'code': key, 'caption': key.replace(caption.code, caption.caption), 'actions': sortedObject[key] };
            })
        },

        getProductSubtitleDescription(product) {
            const description = product.descriptions.find(description => description.category_id == this.descriptionProductSubtitleCode);
            return description ? description.short_description : '';
        },

        getProductShortDescription(product) {
            const description = product.descriptions.find(description => description.category_id == this.descriptionProductCode);
            return description ? description.short_description : '';
        },

        getProductFullDescription(product) {
            const description = product.descriptions.find(description => description.category_id == this.descriptionProductCode);
            return description ? description.full_description : '';
        },

        getSessionsLocation() {
            this.sessions.forEach((session, index) => {

                const locationCode = session.location.code;
                const locationDescription = session.location.description;

                if (!(locationCode in this.sessionsLocation)) {
                    this.sessionsLocation[locationCode] = {
                        location: locationDescription,
                        value: index === 0
                    };
                }
            });

            const sortedKeys = Object.keys(this.sessionsLocation).sort((a, b) => {
                if (a === "5" || a === "6") return 1;
                if (b === "5" || b === "6") return -1;
                return a.localeCompare(b);
            });

            const sortedSelectedLocation = {};
            sortedKeys.forEach((key) => {
                sortedSelectedLocation[this.sessionsLocation[key].location] = this.sessionsLocation[key].value;
            });

            this.sessionsLocation = sortedSelectedLocation;
        },

    },

    watch: {

        sessionsLocation: {
            deep: true,
            handler() {
                if(this.sessions.length > 4) {
                    this.filterSessions()
                }
            },
        },
    },

}

</script>

<style scoped>

p {
    margin-bottom: 10px !important;
}

.title {
    font-size: 18px;
    white-space: normal;
    text-align: start;
}

.subtitle-description {
    font-size: 15px;
    color: #10757a;
}

.description {
    font-size: 14px;
    color: #6B6C80;
}

.select-session {
    background-color: white;
}

.session-label {
    font-size: 14px;
    color: #272954;
}

.session-date {
    font-size: 12px;
    color: #272954;
}

.session-location {
    font-size: 12px;
    color: #272954;
}

.reservation-btn {
    background-color:#ea4899;
    color: white !important;
    padding-left: 15px!important;
    padding-right: 15px!important;
    font-size: 15px;
}

.full-text {
    padding-left: 15px!important;
    padding-right: 15px!important;
    font-size: 15px;
}

.custom-col {
  height: 40px;
}

</style>